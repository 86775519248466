<template>
  <div class="wrapper">
    <div class="banner">
      <img src="../../assets/img/banner.png" alt="" />
    </div>
    <!-- <div class="breadcrumb_box">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/digitalService' }"
          >扩容提质服务</el-breadcrumb-item
        >
        <el-breadcrumb-item>工商信息验证</el-breadcrumb-item>
      </el-breadcrumb>
    </div> -->

    <div class="container">
      <div class="box">
        <div class="one_title">工商信息验证</div>
        <div class="gsxx_box">
          <div class="left_img">
            <img
              src="../../assets/img/digitalService/gsxxyz/left_img.png"
              alt=""
            />
          </div>
          <div class="right_content">
            保障交易安全，确保企业真实。我们致力于为您提供最全面、最准确的工商信息，助您在商业活动中
            游刃有余，安全无忧。提供一站式的工商信息验证服务，通过我们的专业平台，您可以轻松查询到企
            业名称、注册地址、法人代表、注册资本、经营范围等关键信息，确保您所依赖的合作伙伴或交易对象真实存在。
          </div>
        </div>
        <!-- <p>
          保障交易安全，确保企业真实。
          我们致力于为您提供最全面、最准确的工商信息，助您在商业活动中游刃有余，安全无忧。提供一站式的工商信息验证服务。通过我们的专业平台，您可以轻松查询到企业名称、注册地址、法人代表、注册资本、经营范围等关键信息，确保您所依赖的合作伙伴或交易对象真实存在。
          <span>服务特点：</span>
          <span>1.全面覆盖：我们提供全国各地企业的工商信息查询服务，包括企业名称、注册号/注册地址、法人代表、注册资本、经营范围等。</span>
          <span>2.准确可靠：我们的数据来源于权威的工商部门，经过严格的审核和校对，确保信息的准确性和可靠性。</span>
          <span>3.便捷高效：只需简单输入企业名称或关键词，即可快速获取所需信息，无需繁琐的查询过程。</span>
          <span>4.定制服务：根据您的需求，我们提供个性化的定制服务，满足您在商业合作、投资、交易等方面的信息需求。</span>
          <span>5.安全保障：我们非常重视您的信息安全。在查询过程中，我们会严格保护您的隐私，不会泄露任何未经您授权的信息。同时，我们也提供安全可靠的支付方式，保障您的交易安全。</span>
          期待着您的咨询，让我们一起为保障交易安全，确保企业真实而努力！无论您是企业家、投资者、还是普通消费者，我们都将竭诚为您提供最优质的服务，保障您的交易安全，让您安心、放心、满意！
        </p> -->
      </div>
    </div>

    <div class="container container_bg">
      <div class="box">
        <div class="one_title">服务特点</div>
        <div class="td_list">
          <div class="td_item">
            <div class="td_icon">
              <img
                src="../../assets/img/digitalService/gsxxyz/td1.png"
                alt=""
              />
            </div>
            <div class="td_title">全面覆盖</div>
            <div class="td_content">
              <span> 我们提供全国各地企业的工商信息 </span>
              <span> 查询服务，包括企业名称、 </span>
              <span> 注册地址、法人代表、注册资本 </span>
              <span> 经营范围等 </span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_icon">
              <img
                src="../../assets/img/digitalService/gsxxyz/td1.png"
                alt=""
              />
            </div>
            <div class="td_title">准确可靠</div>
            <div class="td_content">
              <span>我们的数据来源于权威的工商部门</span>
              <span>经过严格的审核和校对，确保信息</span>
              <span>的准确性和可靠性</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_icon">
              <img
                src="../../assets/img/digitalService/gsxxyz/td1.png"
                alt=""
              />
            </div>
            <div class="td_title">便捷高效</div>
            <div class="td_content">
              <span>只需简单输入企业名称</span>
              <span>即可快速获取所需信息</span>
              <span>无需繁琐的查询过程</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_icon">
              <img
                src="../../assets/img/digitalService/gsxxyz/td1.png"
                alt=""
              />
            </div>
            <div class="td_title">定制服务</div>
            <div class="td_content">
              <span>根据您的需求，我们提供个性化 </span>
              <span>的定制服务，满足您在商业合作</span>
              <span>投资、交易等方面的信息需求</span>
            </div>
          </div>
          <div class="td_item">
            <div class="td_icon">
              <img
                src="../../assets/img/digitalService/gsxxyz/td1.png"
                alt=""
              />
            </div>
            <div class="td_title">安全保障</div>
            <div class="td_content">
              <span>我们非常重视您的信息安全 </span>
              <span>在查询过程中</span>
              <span>我们会严格保护您的隐私</span>
              <span>不会泄露任何未经您授权的信息</span>
            </div>
          </div>
          <div class="td_item ty_box">
            <p>
              期待着您的咨询，让我们一起为保障交易安全，确保企业真实而努力!无论您是企业家，投资者，普通消费者，我们都将竭诚为您提供最优质的服务，保障您的交易安全让您安心、放心、满意!
            </p>
            <div class="ty_btn" @click="handleApply">立即体验</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 一键办理弹出框 -->
    <el-dialog
      title="预留信息"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :before-close="dialogColse"
    >
      <div class="dialog_head">
        <span>我们的客户经理将在1个工作日内与您联系！</span>
      </div>
      <div class="register_phone">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="10px"
          class="demo-ruleForm"
        >
          <el-form-item prop="reserveName">
            <el-input
              prefix-icon="el-icon-user"
              v-model="ruleForm.reserveName"
              placeholder="请输入称呼昵称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reservePhone">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              v-model="ruleForm.reservePhone"
              maxlength="11"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reserveEntName">
            <el-input
              prefix-icon="el-icon-office-building"
              v-model="ruleForm.reserveEntName"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
          <el-form-item prop="remark">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2 }"
              v-model="ruleForm.remark"
              placeholder="备注......(200字以内)"
              maxlength="200"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >提交预约</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog :visible.sync="dialogSuccess" width="25%" center>
      <div class="success_icon">
        <img src="../../assets/img/success.png" alt="" />
      </div>
      <div class="success_title">提交成功</div>
      <div class="success_content">
        <p>随后工作人员将与您联系，请保持电话畅通。</p>
        <p>咨询热线：<span>400-666-3369</span></p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addReserve } from "../../api/reserve";
export default {
  components: {},
  props: {},
  data() {
    return {
      dialogSuccess: false,
      dialogVisible: false,
      registerPhone: "",
      ruleForm: {
        reserveName: "",
        reservePhone: "",
        reserveEntName: "",
        remark: "",
      },
      rules: {
        reserveName: [
          { required: true, message: "请输入预约人名称", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        reservePhone: [
          { required: true, message: "请输入手机号码", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {},
  computed: {},
  methods: {
    // subInfo() {
    //   this.$router.push({
    //     path: "/info",
    //     query: { org: "sz" },
    //   });
    // },
    onSubmit() {
      this.dialogVisible = true;
    },
    // 体验信息提交
    handleApply() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm.reserveType = "16";
          addReserve(this.ruleForm).then((response) => {
            // if (response.code == 200) {
            //   // this.$modal.msgSuccess("意见办理注册预约成功");

            // }
            this.dialogSuccess = true;
            this.dialogVisible = false;
          });
        }
      });
    },
    dialogColse() {
      this.$refs.ruleForm.resetFields();
      this.dialogVisible = false;
    },
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 300px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.breadcrumb_box {
  width: 1200px;
  padding: 30px 0 0;
  margin: 0 auto;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  padding: 30px 0;
  margin: 0 auto;
}

.img_box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 80px; */
}

.img_box span {
  font-size: 18px;
  font-weight: 600;
  color: #333333;
}

.img_box img {
  display: block;
  width: 600px;
  height: 530px;
}

.simg_box {
  display: flex;
  justify-content: center;
}

.img_box1 {
  width: 50%;
}

.img_box1 img {
  width: 200px;
  height: 350px;
}

.box p {
  font-size: 18px;
  color: #333333;
  line-height: 34px;
  padding: 20px 0;
  text-align: justify;
}

.box p:first-child {
  padding: 0;
}

.box p span {
  display: block;
  /* font-weight: bold; */
  margin: 10px 0;
}

.btn {
  width: 130px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 25px;
  background: #055afe;
  color: #fff;
  margin: 20px auto;
  cursor: pointer;
}

table tr th {
  text-align: center;
  padding: 5px;
  font-size: 14px;
}

table tr td {
  text-align: center;
  padding: 10px;
}

.one_title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}

.gsxx_box {
  display: flex;
  align-items: center;
  margin-top: 60px;
}

.left_img {
  width: 472px;
  height: 314px;
}

.left_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.right_content {
  flex: 1;
  padding: 40px;
  font-size: 18px;
  line-height: 30px;
}

.td_list {
  width: 1000px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  margin-top: 60px;
}

.td_item {
  background: #fff;
  width: 270px;
  height: 298px;
  background: #ffffff;
  border-top: 8px solid #055afe;
  box-shadow: -1px 1px 9px 0px rgba(120, 117, 117, 0.2);
  border-radius: 8px;
  padding: 40px 30px 0;
  margin-bottom: 30px;
}

.td_icon {
  width: 66px;
  height: 66px;
  margin: 0 auto;
}

.td_icon img {
  display: block;
  width: 100%;
}

.td_title {
  width: 100%;
  text-align: center;
  font-size: 24px;
  color: #000000;
  margin-top: 17px;
  margin-bottom: 22px;
}

.td_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.td_content span {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.ty_box {
  background: #eaf0fb;
  padding: 20px 30px 0;
}

.ty_box p {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #000000;
}

.ty_btn {
  width: 148px;
  height: 33px;
  background: #055afe;
  border-radius: 16px;
  line-height: 33px;
  color: #fff;
  text-align: center;

  margin: 0 auto;
  margin-top: 10px;
  cursor: pointer;
}


.el-form {
  text-align: center;
}
.dialog_head {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #055afe;
  margin-bottom: 20px;
}
.success_icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}

.success_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.success_title {
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin: 30px 0;
}

.success_content {
  width: 100%;
  text-align: center;
  font-size: 18px;
  color: #919191;
}
.success_content p span {
  color: #055afe;
}
</style>
